import React from "react";

import TextWithIcon from "./textWithIcon";

import "../../styling/libraries.scss"
import { colors } from "../../styling/colors";

const Highlights = props => {

  const contentRows = (props.items || [])
    // .filter((component) => !component.disabled)
    .map((component, i) => {
      let el = null;
      
      if (component && component._type) {
        switch (component._type) {
          case "highlight":
            el = <TextWithIcon i={i} key={'highlight' + i} {...component} />;
            break;
          default:
            el = <div key="undefined">Udefinert innholdstype: {component._type}</div>;
        }
      }
      return el;
    });

  return (
    <div className="section" style={{'background': colors.green03}}>
        <div className="container">
            <div className="section">
                <div className="columns">
                    <div className="column is-offset-1 is-10">
                        <h2 className="title is-3 py-4 has-text-centered mb-6">
                            {props.title}
                        </h2>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="columns">
                    {contentRows}
                </div>
            </div>

        </div>
    </div>
  );
};

export default Highlights;
