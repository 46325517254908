import React from "react";
import styled from '@emotion/styled';

import PortableText from "./portableText";

import LinkComponent from "./link";

import "../../styling/libraries.scss"

const RichTextBlockStyle = styled.section`
  p {
    font-size: ${props => props.index === 0 ? '1.3em' : ''};
  }

  h2 {
    font-size: 1.3em;
    font-weight: 600;
  }

  ul {
    list-style: disc;
    margin-left: 1em;
  }
`

function RichTextBlock (props) {

  return (
    <RichTextBlockStyle 
      bg={props.bg}
      index={props.index}
      key={props.title}>

      <div className="container">

        <h1 className="title is-1-desktop">{props.title}</h1>

        {props._rawRichText && <PortableText portableTextContent={props._rawRichText} />}

        <div className="is-flex is-justify-content-flex-end">

          <div className="buttons mt-2">
            {(props.links || []).map((link, i) => (

              <LinkComponent key={i} {...link} arrow />
            
            ))}
          </div>

        </div>

      </div>

    </RichTextBlockStyle>
  )
}

export default RichTextBlock;
