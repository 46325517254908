import React from "react";
import styled from '@emotion/styled';

import RichText from "./portableText";

import LinkComponent from "./link";

import "../../styling/libraries.scss"

const SectionStyle = styled.li`

  .inner {
    background: ${props => props.bg ? props.bg : ''};
    height: 100%;
    padding: 1.5em 3em 3em;
  }

  .bigNumber {
    font-size: 2.5em;
    font-weight: 600;
    text-align: center;
    display: block;
  }

  .dottedLine {
    margin-bottom: 1em;
    margin-left: -3em;
  }

  .buttons {
      display: block;
      text-align: center;
  }
`

function Step (props) {

  return (
    <SectionStyle 
      bg={props.bg}
      className="column"
      index={props.index}>

        <div className="inner">

            <span className="bigNumber">
              {(props.index + 1) < 9 ? 0 : null}
              {props.index + 1}
            </span>

            <svg className="dottedLine" width="240" height="2" viewBox="0 0 240 2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="1" x2="240" y2="1" stroke="#1F1E40" strokeWidth="2" strokeDasharray="5 5"/>
            </svg>

            {props.title && <h3 className="title is-size-5" style={{'fontWeight': '700'}}>{props.title}</h3>}

            {props._rawRichText && <RichText portableTextContent={props._rawRichText} />}
        
            <div className="buttons mt-2">
              {(props.links || []).map((link, i) => (
                <LinkComponent 
                  className="button" 
                  key={i} 
                  linkText={link.linkText} 
                  slug={link.page && link.page.slug ? ('/' + link.page.slug.current) : ''} 
                />
              ))}
            </div>

        </div>

    </SectionStyle>
  )
}

export default Step;
