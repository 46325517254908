import React from "react";

import Step from "./step";

import "../../styling/libraries.scss"
import { colors } from "../../styling/colors";

const Collection = props => {

  const contentRows = (props.items || [])
    // .filter((component) => !component.disabled)
    .map((component, i) => {
      let el = null;

      if (component && component._type) {
        switch (component._type) {
          case "step":
            el = <Step bg={colors.green02} index={i} key={'step' + i} {...component} />;
            break;
          default:
            el = <div key="undefined">Udefinert innholdstype: {component._type}</div>;
        }
      }
      return el;
    });

  return (
    <div className="container">
      <div className="section">
        <div className="columns">
          <div className="column is-offset-1 is-10">
            <h2 className="title is-3 py-4">
              {props.title}
            </h2>
          </div>
        </div>

        <ol className='columns'>
          {contentRows}
        </ol>

      </div>
    </div>
  );
};

export default Collection;
