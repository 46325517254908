import React from "react"
import { graphql } from 'gatsby'
import styled from '@emotion/styled';

import Container from '../components/layout/container'
import StoriesFromProfessionals from '../components/editorial/storiesFromProfessionals'
import Steps from '../components/editorial/steps'
import Section from '../components/editorial/section'
import Quote from "../components/editorial/quote";
import RichTextBlock from "../components/editorial/richTextBlock";
import Phases from "../components/editorial/phases";
import Highlights from "../components/editorial/highlights";

import SEO from '../components/seo'

import { typescale } from "../styling/typescale";
import { breakpoints } from "../styling/breakpoints";
import { colors } from "../styling/colors";

const Heading = styled.h1`
  ${typescale['title-1']};
  @media only screen and (${breakpoints.mobile}) {
    ${typescale['title-2']};
  }
`

export default function PageTemplate({ data }) {
 
  const pageData = data ? data.sanityPage : null

  return (
    <Container>

      <SEO 
        title={pageData.title}
        description={pageData.intro ? pageData.intro : ''}
        pathname="/"
        image={pageData.mainImage && pageData.mainImage.asset && pageData.mainImage.asset.fixed ? pageData.mainImage.asset.fixed : null}
      />

      <div className="container">

        <div className="section py-0">
          
          <div className="columns">
            <div className="column is-offset-1 is-6 mt-6">

              {pageData.displayTitle 
                ? <Heading>{pageData.displayTitle}</Heading> 
                : <Heading>{pageData.title}</Heading>
              }
              
              {pageData.intro && <p className="is-size-5">{pageData.intro}</p>}

            </div>
          </div>

        </div>
        
      </div>

      {pageData.content.map((item, index) => {

        let element;
        
        if (item && item._type) {
          switch(item._type) {
            case 'highlights':
              element = <Highlights key={index + item._type} {...item} />
              break;
            case 'phases':
              element = <Phases key={index + item._type} {...item} />
              break;
            case 'richTextBlock':
              element = <RichTextBlock key={index + item._type} {...item} />
              break;
            case 'section':
              element = <Section 
                bg={(index + 1) === pageData.content.length ? colors.lightgreen02 : ''}
                index={index} 
                key={index + item._type} 
                {...item} 
                title={item.title && <h2 className="title is-3">{item.title}</h2>}
              />
              break;
            case 'storiesFromProfessionals':
              element = <StoriesFromProfessionals key={index + item._type} {...item} />
              break;
            case 'story':
              element = <Quote 
                i={index}
                key={item.id} 
                quote={item.intro}
                link={item.slug}
                author={item.title} 
                image={item.mainImage}
              />
              break;
            case 'steps':
              element = <Steps key={index + item._type} {...item} />
              break;
            default:
              element = <div>Udefinert innholdstype: {item._type}</div>
              break;
          }
        }

        return element;

      })}

    </Container>
  )
}

export const query = graphql`
  query Page($slug: String!) {
    sanityPage(slug: {current: {eq: $slug}}) {
      title
      displayTitle
      intro
      slug {
        current
      }
      content {
        ... on SanityStoriesFromProfessionals {
          _key
          _type
          title
          items {
            title
            intro
            mainImage {
              asset {
                fixed(width: 485, height: 292) {
                  src
                }
              }
            }
            slug {
              current
            }
            professionalName
            professionalTitle
            readMoreLinkText
          }
        }
        ... on SanityHighlights {
          _key
          _type
          title
          items {
            _type
            _rawRichText
            mainImage {
              asset {
                fixed {
                  src
                }
              }
            }
          }
        }
        ... on SanitySteps {
          _key
          _type
          title
          items {
            title
            _type
            _rawRichText
            mainImage {
              asset {
                fixed {
                  src
                }
              }
            }
          }
        }
        ... on SanityPhases {
          _key
          _type
          title
          items {
            _type
            title
            _rawRichText
            mainImage {
              asset {
                fixed {
                  src
                }
              }
            }
          }
        }
        ... on SanityRichTextBlock {
          _key
          _type
          title
          _rawRichText
        }
        ... on SanitySection {
          _key
          _type
          _rawRichText
          title
          video
          mainImage {
            _type
            caption
            alt
            attribution
            asset {
              fixed {
                src
                width
                height
              }
            }
            width
            height
          }
        }
        ... on SanitySteps {
          _key
          _type
          title
          items {
            _type
            _rawRichText
            title
            links {
              externalURL
              linkText
              page {
                slug {
                  current
                }
              }
            }
          }
        }
      }
      mainImage {
        asset {
          fixed {
            src
            width
            height
          }
        }
        alt
        caption
        attribution
      }
    }
  }
`