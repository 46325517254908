import React from "react";
import styled from '@emotion/styled';

import Story from "./story";

import PresentationIcon from "./../../icons/presentation.svg";
import "../../styling/libraries.scss"
import { colors } from "../../styling/colors";

const CollectionStyle = styled.div`
  .section .section {  
    padding: 0;
  }
`

const StoriesFromProfessionals = props => {

  const contentRows = (props.items || [])
    .map((component, i) => {
        return <Story 
          i={i} 
          key={'story' + i} 
          image={component.mainImage}
          {...component} 
        />
    });

  return (
    <CollectionStyle>
      <div className="section" style={{'background': colors.lightgreen02}}>
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-offset-1 is-10">
                <div className="section is-flex is-align-items-center" style={{'marginBottom': '35px'}}>
                  <h2 className="title is-3 py-4 mr-5 mb-0">
                    {props.title}
                  </h2>
                  <PresentationIcon />
                </div>
              </div>
            </div>
          </div>
          <div className="container has-text-centered">
            <div className="section">
              <div className="columns">
                <div className="column is-offset-1 is-10">
                  <div className="section">
                    <div className="columns">
                      {contentRows}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CollectionStyle>
  );
};

export default StoriesFromProfessionals;
