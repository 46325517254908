import React from "react";
import { Link } from "gatsby";
import { css } from '@emotion/css';

import ChevronRight from "./../../icons/Chevron-right.js";
import { colors } from "./../../styling/colors";

const quoteStyling = css`

  height: 100%;

  > a {
    display: block;
    box-shadow: 0;
    transition: .25s;
    border-radius: 3px;
    height: 100%;

    display: flex;
    flex-direction: column;

    .readmoreLink {
      display: flex;
      align-items: center;
      padding-right: 1.5em;

      svg {
        position: absolute;
        transition: .25s;
        right: .5em;
      }
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 5px ${colors.green};
    }

    &:focus,
    &:hover {
      .readmoreLink {
        svg {
          right: 0;
        }
      }
    }

  }

  .intro-text {
    font-size: rem(24px);
    margin-bottom: 1em;
  }

  .author {
    width: 100%;
    display: block;
    font-weight: 600;
    font-style: normal;
    margin: 0;
  }

  .author-title {
    font-style: normal;
    width: 100%;
    display: block;
  }

`

const maybeImage = image => {
  let img = null;
  if (image && image.asset && image.asset.fixed && image.asset.fixed.src) {
    img = (
      <img style={{'width': '100%'}} src={image.asset.fixed.src} alt={image.alt ? image.alt : ''} />
    );
  }
  return img;
};

function Story (props) {
  const img = maybeImage(props.image);

  return (

      <section className="column is-half">

        <div className="container is-max-desktop" style={{'height': '100%'}}>

          <div className="has-text-left" style={{'height': '100%'}}>

            <div className={quoteStyling}>

              <Link to={`/artikler/${props.slug.current}`}>

                <div className="mb-3 auto-resizable-iframe">

                  {img ? img : ''}

                </div>

                <div
                  style={{
                    'height': '100%', 
                    'display': 'flex', 
                    'flexDirection': 'column'
                  }}>
                      
                  <div className="intro-text" style={{'flex': 1}}>
                    {props.intro && <p className="m-0">{props.intro}</p>}
                  </div>
                  
                  <div className="is-flex">

                    <div style={{'flex': '1'}}>
                      <span className="author block">{props.professionalName}</span>
                      <em className="author-title block m-0">{props.professionalTitle}</em>
                    </div>

                    <div className="is-flex is-justify-content-flex-end">
                      {!props.hideReadMore && props.slug && props.slug.current && 
                        <span className="readmoreLink">{props.readMoreLinkText ? props.readMoreLinkText : 'Les mer'} <ChevronRight fill={colors.blue} /></span>
                      }
                    </div>

                  </div>

                </div>

              </Link>

            </div>

        </div>
        
      </div>

    </section>
  )
}

export default Story;
